import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-media";
import "./HomeLandingPage.css";
import Footer from "./Footer";
import UiLoadBtn from "./UiLoadBtn";
import PhoneNumber from "react-phone-number";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Testimonial from "./Testimonial";
import Rating from "react-rating";
import GitHubFeed from "react-github-activity-mobile";
import { eventList } from "../libs/githubEventList";

export default class HomeLandingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			eventLists: [],
			usr: "lopezdp",
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		eventList(this.state.usr)
			.then((response) => response.json())
			.then((res) => {
				this.setState({
					eventLists: res,
				});
				console.log("DavidPLopez.com --> GoHardInThePaint: ", res);
			})
			.catch((error) =>
				console.log("Authorization failed : " + error.message)
			);
	}

	render() {
		return (
			<div className="view">
				{/* NOTE: 319px Small Mobile XS-Views!!!*/}
				<Media
					query="(min-width: 319px) and (max-width: 900px)"
					render={() => (
						<Container>
							<Container className="app-view-xs">
								<Container className="main-view-xs">
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>About Me</h2>
											<p>
												I help business owners with
												successful offline business
												enterprises, navigate the often
												confusing, and complex world of
												software development and
												technology. I am an experienced
												professional with many of my own
												startup successes and failures,
												and I have worked on many of the
												advanced technologies available,
												and soon to be available to the
												market. I want to help you and
												your teams leverage these tools
												successfully, because the time
												to build your online brand and
												business is now. I can guide you
												and help you automate your
												workflow and adapt to the
												changing market conditions that
												you need to take advantage of
												today to take your revenues on
												the path towards exponential
												growth.
											</p>
										</Container>
									</Jumbotron>
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<Row>
												<Col xs={1}>
													<Image
														alt="Location"
														src="/img/ContactIcons/Location.png"
														className="contact-icon"
													/>
												</Col>
												<Col className="contact-info">
													<p>Miami, Florida, USA</p>
												</Col>
											</Row>
											<Row>
												<Col xs={1}>
													<Image
														alt="Email"
														src="/img/ContactIcons/Email.png"
														className="contact-icon"
													/>
												</Col>
												<Col className="contact-info">
													<p>
														<a href="mailto:David P. Lopez<David@NativeStack.io>?subject=I%20need%20an%20engineer%20now!!!">
															Email Me Today!
														</a>
													</p>
												</Col>
											</Row>
											<Row>
												<Col xs={1}>
													<Image
														alt="Phone"
														src="/img/ContactIcons/Phone.png"
														className="contact-icon"
													/>
												</Col>
												<Col className="contact-info">
													<PhoneNumber
														number="+13054192441"
														isLinked={true}
													/>
												</Col>
											</Row>
										</Container>
									</Jumbotron>
									{/*
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container className="git-section btc-section-mobile">
											<h2>Bitcoin Payments</h2>
											<p>
												If you are currently doing
												business with me and you need to
												make a payment please use the
												bitcoin marketplace below.
											</p>

											<div className="iframe-container1 btc-mobile">
												<iframe
													src="https://btcpay.ecomindustries.io/apps/5QW6V7iAAh7pyPZKxZfaXWcNMib/pos"
													allowFullScreen
													className="iframe-class1 btc-pay"
													style={{
														maxWidth: "100%",
														border: " 0",
													}}
												></iframe>
											</div>
										</Container>
									</Jumbotron>
									*/}
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>10x Skill Set</h2>
											<p>
												I am a Sr. Automation Architect
												and Sr. Software Engineer with
												enterprise experience deploying
												apps, services, and Machine
												Learning tools on Amazon Web
												Services (AWS).
											</p>
											<div>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															First Principles
															Approach
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Thinking through First Principles"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>

												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																<i>
																	The
																	naturally
																	proper
																	direction of
																	our road is
																	from things
																	better known
																	and clearer
																	to us, to
																	things that
																	are clearer
																	and better
																	known by
																	nature.
																</i>
																<cite>
																	{" "}
																	- Aristotle
																	(Physica.
																	184a10–21)
																</cite>
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={100}
															/>
														</Col>
													</OverlayTrigger>
												</Row>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>ECOM Payments</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Digital Payments & Tokenization"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>

												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Contributor on
																digital payments
																projects working
																to develop
																digital wallets
																on Android for
																banking
																institutions in
																Latin America &
																Europe, to
																include projects
																with
																BrasilPrePagos,
																VeriTran, Honda,
																Swatch, and
																GooglePay as a
																member of the
																Visa Token
																Service
																Integration
																Team.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={100}
															/>
														</Col>
													</OverlayTrigger>
												</Row>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															JS, React.js,
															Node.js
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="The DARN Tech Stack"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>

												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I specialize in
																developing PWA's
																on AWS using
																DynamoDB, AWS
																Lambda,
																React.js, &
																Node.js{" "}
																<strong>
																	(DARN Stack)
																</strong>
																. I am an expert
																at API
																development with
																Express.js also.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={97}
															/>
														</Col>
													</OverlayTrigger>
												</Row>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Python, Flask,
															Jupyter
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Python & Flask & SageMaker Notebooks"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I excel at
																building data
																driven tools in
																Python to scrape
																web data with
																Selenium or
																Cheerio. I am a{" "}
																<em>
																	Data Science
																</em>{" "}
																expert and I can
																work with
																analysis modules
																like Pandas,
																NumPy,
																SciKit-Learn,
																TensorFlow,
																MatPlotLib,
																SciPy, and more.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={95}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>Java/C++</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Jave & C++ Object Oriented Programming"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Pro
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Object Oriented
																Programming
																expert in Java,
																C++, and C#. I
																can work
																effectively in
																both{" "}
																<em>Unity</em>{" "}
																and{" "}
																<em>
																	UnReal
																	Engine
																</em>{" "}
																to deploy
																Virtual &
																Augmented
																Reality
																applications for
																use cases in the
																B2B environment.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={85}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Blockchain & RSA-PKI
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Digital Encryption Expert"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Pro
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I am a
																professional at
																building{" "}
																<em>
																	mining rigs
																</em>{" "}
																to verify
																transactions on
																any
																cryptocurrency
																network. I can
																help you
																implement
																immutable
																transaction
																ledgers to
																deploy your own
																cryptocurrency
																or SmartContract
																with Ethereum or
																HyperLedger on
																AWS. I also
																provide the
																option of
																working with
																Amazon's new
																Quantum Ledger
																Database
																technology.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={85}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>HTML, CSS, JSON</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Responsive Design Master"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Everything I
																build is{" "}
																<em>
																	Responsive
																</em>{" "}
																and targeted to
																all mobile
																devices using
																modern web
																development
																techniques for
																presenting,
																laying out, and
																parsing or
																passing data on
																the web securely
																and dynamically.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={99}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Mobile App Developer
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Mobile Application Developer"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I can deploy
																applications
																using
																technologies
																native to both
																Android and iOS
																devices or with{" "}
																<em>
																	crossplatform
																</em>{" "}
																architectures to
																deliver{" "}
																<strong>
																	<em>
																		Progressive
																		Web
																		Applications
																		(PWA's)
																	</em>
																</strong>{" "}
																that target any
																mobile device
																available on the
																market. Ignoring
																mobile is to
																ignore market
																share.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={95}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															API Developer & ReST
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="API Developer & ReST Expert"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Expert at the
																implementation
																of secure{" "}
																<em>
																	Application
																	Programming
																	Interfaces
																</em>{" "}
																that you can use
																to help your
																users leverage
																your platform to
																automate and
																scale to a
																global audience
																autonomously. An
																expert with RSA
																Encryption
																techniques that
																will enable you
																to share
																assymetric
																(public) keys
																with your users
																to let them
																access your
																platform with
																their own custom
																automation
																tools.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={95}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Ai & Machine
															Learning
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Ai & Machine Learning Jedi"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Pro
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																The machines are
																coming. Join
																them with me. I
																am now on record
																for being a
																friend to the{" "}
																<strong>
																	Ai
																</strong>{" "}
																from day one.{" "}
																<em>
																	Are we in a
																	simulation
																</em>
																? Let me build
																you a{" "}
																<strong>
																	ChatBot
																</strong>{" "}
																that will talk
																to the machine
																for us and{" "}
																<i>
																	Let's find
																	out
																</i>
																! Adaptive &
																Assistive
																technologies are
																here, now is the
																time to lead the
																pack. Will you
																join me?
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={90}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://profile.codersrank.io/user/lopezdp">
															See more on
															CodersRank
														</a>
													</Col>
												</Row>
											</div>
										</Container>
									</Jumbotron>
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>Languages</h2>
											<p className="language-context">
												<strong>English:</strong> Native
												Speaker
											</p>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="5"
												readonly
											/>
											<p className="language-context">
												<strong>Spanish:</strong>{" "}
												Native/Bilingual
											</p>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="5"
												readonly
											/>
											<p className="language-context">
												<strong>Portuguese:</strong>{" "}
												Fluent
											</p>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="4"
												readonly
											/>
											<OverlayTrigger
												key="bottom"
												placement="bottom"
												overlay={
													<Tooltip id="tooltip-bottom">
														обидно досадно но ладно
													</Tooltip>
												}
											>
												<p className="language-context">
													<strong>Russian:</strong>{" "}
													Read Only Proficiency
												</p>
											</OverlayTrigger>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="1"
												readonly
											/>
										</Container>
									</Jumbotron>
									<Testimonial />
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>Current Projects</h2>
											<Container className="feature-content-xs">
												<h3 className="feature-content-title">
													C-Suite CRM
												</h3>
												<p className="feature-summary">
													Finally a low cost CRM and
													ERP solution that adds value
													to your bottom line to allow
													you to keep your business
													data and do with it as you
													see fit. Your Platform, Your
													Data.
												</p>
												<article className="feature-image has-ribbon">
													<Image
														alt="Feature Project"
														src="/img/LandingPageImages/FeaturedProject.png"
														className="project-image"
													/>
													<article className="ribbon">
														<section className="ribbon-text">
															NEW
														</section>
													</article>
												</article>
												<article className="feature-description-xs">
													A new CRM and ERP solution
													to replace expensive
													platforms like NetSuite and
													SalesForce so you can focus
													on what you do best without
													liquidating your bottom
													line. A serverless
													application built with
													DynamoDB, AWS Lambda
													(ServerlessFramework),
													React.js, and Node.js
													(Python is used in data
													intensive services). Your
													data is always accessible
													because you own it. No need
													to fear the loss of your
													data because of an inability
													to pay your subscription
													fees.
												</article>
												<Container className="back-project-cta">
													<a
														href="https://saas.ecomindustries.io/auto-pilot"
														onClick={void 0}
														onTouchStart={() => ""}
														className="feat-link"
													>
														<Row className="like-col-xs">
															<div className="like-btn-col-xs">
																<Image
																	alt="Gmail"
																	src="/img/LandingPageImages/LikeBtnIcon.png"
																	className="like-btn-icon"
																/>
																<strong>
																	Back My
																	Project
																</strong>
															</div>
														</Row>
													</a>
												</Container>
											</Container>
											<hr className="divider" />

											<Container>
												{/*
												<a
													href="https://chargebackguardians.com"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="ChargebackGuardians"
																	src="https://cdn-assets.tudorcapital.ventures/brand-logos/CBG.Icon.png"
																	className="project-icon-qat-xs"
																/>
															</article>
															<h3 className="featured-titles">
																ChargebackGuardians:
																Loss Prevention
																Alerts &
																Recovery
															</h3>
															<p className="p-desc">
																An Artificially
																Intelligent
																Dispute
																Resolution
																Platform that
																automates the
																resolution of
																chargebacks with
																connections to
																Ethoca
																(MasterCard) &
																Verifi (Visa) to
																provide a
																comprehensive,
																multi-layer
																approach to
																resolving
																disputes and
																preventing
																chargebacks.
																Automating the
																resolution of
																chargebacks
																using
																DisputeGuardAi
																and FraudGuardAi
																Prevention
																Alerts to Refund
																transaction
																disputes, we
																help you prevent
																threshold
																breaches, and
																keep merchant
																accounts in good
																standing. The
																technology stack
																deployed uses{" "}
																<strong>
																	DynamoDB
																	Data
																	Streaming,
																	AWS Lambda
																	with the
																	ServerlessFramework,
																	React.js,
																	Node.js,
																	Python,
																	Infrastructure
																	As Code,
																	CodeBuild
																	for
																	Continuous
																	Integration,
																	CodePipeline
																	for
																	Continuous
																	Deployment,
																	CloudWatch
																	for
																	Application
																	Logs, Amazon
																	Cognito for
																	User
																	Authentication
																	&
																	Authorization,
																	API Gateway
																	for Endpoint
																	Security,
																	NativeStack
																	on the AWS
																	Cloud.
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>

												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://chargebackguardians.com">
															Find out more
														</a>
													</Col>
												</Row>

												*/}

												{/*
												<a
													href="https://dev.d3dbxu13fdlypu.amplifyapp.com/"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="Southland Electrical"
																	src="https://cdn-assets.nativestack.io/NativeStackMerchantResources/SouthlandElectricAssets/SES_OFFICIAL_LOGO_2016.png"
																	className="project-icon-qat-xs"
																/>
															</article>

															<h3 className="featured-titles">
																SouthlandElectrical:
																Industry Leaders
																in Motor Control
																Centers
															</h3>
															<p className="p-desc">
																Southland is an
																industry leader
																in custom built
																low and medium
																voltage motor
																control center
																buckets and
																verticals. Our
																extensive
																inventory of MCC
																buckets and
																verticals spans
																over 75 years
																from every major
																manufacturer
																including
																obsolete and
																discontinued
																designs, and our
																UL 508A Panel
																Shop is
																certified to
																build custom MCC
																buckets and
																panels. We
																specialize life
																extension/modernization
																and include ARC
																flash
																reduction/mitigation
																and smart MCC’s
																that incorporate
																communications
																and remote
																switching. Our
																quick ship
																program includes
																new, new surplus
																and
																professionally
																reconditioned
																MCC projects.
																The technology
																stack deployed
																uses{" "}
																<strong>
																	DynamoDB
																	Data
																	Streaming,
																	AWS Lambda
																	with the
																	ServerlessFramework,
																	React.js,
																	Node.js,
																	Python,
																	Infrastructure
																	As Code,
																	CodeBuild
																	for
																	Continuous
																	Integration,
																	CodePipeline
																	for
																	Continuous
																	Deployment,
																	CloudWatch
																	for
																	Application
																	Logs, Amazon
																	Cognito for
																	User
																	Authentication
																	&
																	Authorization,
																	API Gateway
																	for Endpoint
																	Security,
																	NativeStack
																	on the AWS
																	Cloud
																	ElasticSearch
																	Service
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://dev.d3dbxu13fdlypu.amplifyapp.com/">
															Find out more
														</a>
													</Col>
												</Row>

												*/}

												{/*

												<a
													href="https://tudorcapital.fund"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="TudorCapital Funding"
																	src="https://cdn-assets.tudorcapital.ventures/brand-logos/TudorCapBrand.svg"
																	className="project-icon-qat-xs"
																/>
															</article>
															<h3 className="featured-titles-xs">
																TudorCapital:
																Small Business
																Funding &
																Merchant Account
																Payments
																Processing
															</h3>
															<p className="p-desc">
																Online funding
																platform for
																small business
																owners who need
																capital &
																funding to help
																scale their
																business online.
																Connect, Verify,
																& Fund with
																TudorCapital to
																build an
																ecommerce brand
																on an integrated
																lending platform
																that guarantees
																the health &
																sustainability
																of a business.
																The technology
																stack deployed
																uses{" "}
																<strong>
																	AWS Amplify,
																	DynamoDB,
																	AWS Lambda
																	on the
																	Serverless
																	Framework,
																	React.js,
																	Node.js
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://tudorcapital.fund">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://dx.bloktime.io"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="Blockchain Engineer"
																	src="/img/ProjectIcons/BlokTime.png"
																	className="project-icon-qat-xs"
																/>
															</article>
															<h3 className="featured-titles">
																NFT Marketplace
															</h3>
															<p className="p-desc">
																Web Application
																for BlokTime NFT
																Marketplace,
																designed to
																facilitate user
																interaction with
																digital assets
																via ERC721 and
																ERC1155 smart
																contracts.
																Utilized
																Web3Modal for
																seamless wallet
																integration,
																enabling
																interactions
																with the
																Ethereum
																blockchain
																through a Chrome
																extension.{" "}
																<strong>
																	Developed
																	smart
																	contracts
																	using
																	HardHat,
																	ensuring
																	robust
																	compilation,
																	migration,
																	and
																	deployment.
																	Leveraged
																	Ethers.js/Web3js
																	libraries
																	for
																	efficient
																	interaction
																	with the
																	Ethereum
																	network and
																	smart
																	contracts.
																	Integrated
																	Open
																	Zeppelin
																	packages for
																	secure and
																	audited
																	implementation
																	of
																	blockchain
																	standards.
																	Employed
																	IPFS for
																	decentralized
																	and
																	peer-to-peer
																	sharing of
																	asset
																	metadata.
																	Implemented
																	unit testing
																	of smart
																	contracts
																	using Mocha
																	and Chai.
																	Frontend
																	developed
																	using
																	React.js,
																	HTML, and
																	CSS.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://dx.bloktime.io">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://wallet.bloktime.io"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="Blockchain Engineer"
																	src="/img/ProjectIcons/BlokTime.png"
																	className="project-icon-qat-xs"
																/>
															</article>
															<h3 className="featured-titles-xs">
																Web3 Wallet, NFT
																Music Player, &
																Live-Streaming
															</h3>
															<p className="p-desc">
																Web Application
																for a
																Decentralized
																Wallet,
																engineered to
																power a live
																streaming
																shopping
																platform and
																Web3 music
																experience on
																iOS Safari
																browsers.
																Enables users to
																effortlessly
																engage with
																merchants and
																execute
																one-click crypto
																payments, while
																also providing
																the capability
																to play Web3
																music as NFTs
																directly from
																the wallet
																interface.
																Integrated with
																the Ethereum
																blockchain, the
																wallet leverages
																Web3Modal for a
																seamless and
																secure user
																experience.
																Designed to
																facilitate
																real-time
																transactions
																using various
																cryptocurrencies,
																the wallet
																ensures
																instantaneous
																and secure
																payments to
																merchants during
																live streams.
																The wallet's
																multi-functional
																design also
																allows for the
																purchase,
																storage, and
																playback of Web3
																music NFTs,
																offering a
																comprehensive
																digital asset
																experience.{" "}
																<strong>
																	Developed
																	smart
																	contracts
																	using
																	HardHat,
																	ensuring
																	robust
																	compilation,
																	migration,
																	and
																	deployment.
																	Leveraged
																	Ethers.js/Web3js
																	libraries
																	for
																	efficient
																	interaction
																	with the
																	Ethereum
																	network and
																	smart
																	contracts.
																	Integrated
																	Open
																	Zeppelin
																	packages for
																	secure and
																	audited
																	implementation
																	of
																	blockchain
																	standards.
																	Employed
																	IPFS for
																	decentralized
																	and
																	peer-to-peer
																	sharing of
																	asset
																	metadata.
																	Implemented
																	unit testing
																	of smart
																	contracts
																	using Mocha
																	and Chai.
																	Frontend
																	developed
																	using
																	React.js,
																	HTML, and
																	CSS.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://wallet.bloktime.io">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://renew.quickautotags.com"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="California DMV"
																	src="/img/ProjectIcons/Logo.QAT.png"
																	className="project-icon-qat-xs"
																/>
															</article>
															<h3 className="featured-titles-xs">
																QuickAutoTags:
																California DMV
																Renewal
																Registration
																System
															</h3>
															<p className="p-desc">
																Online Auto Tag
																registration
																renewal platform
																that connects to
																a government API
																with encryption
																requirements for
																data{" "}
																<em>at-rest</em>{" "}
																and{" "}
																<em>
																	in-transit
																</em>{" "}
																to secure the
																automation of
																auto tag
																renewals,
																vehicle title
																transfers, and
																other services
																offered by the
																California DMV.
																The technology
																stack deployed
																uses{" "}
																<strong>
																	DynamoDB,
																	AWS Lambda
																	on the
																	Serverless
																	Framework,
																	React.js,
																	Node.js
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://renew.quickautotags.com">
															Find out more
														</a>
													</Col>
												</Row>
*/}
												{/*
												<a
													href="https://SpecTruMetRx.com"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col className="featured-desc">
															<article className="featured-img-xs">
																<Image
																	alt="Medical Payments"
																	src="https://spectrumetrx.com/img/dicom.png"
																	className="project-icon-med-spec-xs"
																/>
															</article>
															<h3 className="featured-titles-xs">
																SpecTruMetRx:
																Medical ERP,
																CRM, Payments &
																Analytics on the
																Blockchain
															</h3>
															<p className="p-desc">
																Healthcare
																Analytics &
																Digital Medical
																Payments and
																Lending Platform
																on the
																Blockchain. Ai
																based payments
																network for
																healthcare
																providers built
																on{" "}
																<strong>
																	DynamoDB,
																	AWS Lambda
																	on the
																	Serverless
																	Framework,
																	React.js, &
																	Node.js
																	built with
																	Infrastructure
																	As Code
																	Architecture
																	on
																	CloudFormation
																	using Amazon
																	Cognito,
																	CodeBuild,
																	CodePipeline,
																	APIGateway,
																	& Native to
																	AWS.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://SpecTruMetRx.com">
															Find out more
														</a>
													</Col>
												</Row>

												*/}
											</Container>
										</Container>
									</Jumbotron>

									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>Previous Projects</h2>
											<Container className="feat-container-xs">
												<Link
													to="/ai-machine-learning-research"
													className="feat-link"
												>
													<section className="featured-img-xs">
														<Image
															alt="Machine Learning & Artificial Intelligence"
															src="/img/ProjectIcons/AI.png"
															className="project-icon-ML-xs"
														/>
													</section>
													<Row className="featured-item-xs">
														<Col className="featured-desc">
															<h3 className="featured-titles">
																Machine Learning
																Research
															</h3>
															<p className="p-desc">
																Artificial
																Intelligence &
																Machine Learning
																OpenSource
																research
																repository.
																Created for the
																MDC STEM
																Research
																Institute, these
																project
																represent a
																small selection
																of work I
																created to
																analyze data and
																predict the
																outcomes
																questioned
																within the
																research
																published in
																these archives.
																Machine Learning
																Research -
																Preprocessing,
																Feature
																Engineering,
																Modeling,
																Learning/Training,
																and Predictions
																in ML:{" "}
																<strong>
																	Jupyter Note
																	book,
																	Python,
																	Tensor Flow,
																	Pandas, Num
																	Py, Sci Kit
																	Learn,
																	MatPlot Lib,
																	Theano,
																	Keras,
																	Seaborn, &
																	NLTK.
																</strong>
															</p>
														</Col>
													</Row>
												</Link>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<Link to="/ai-machine-learning-research">
															Find out more
														</Link>
													</Col>
												</Row>

												<a
													href="https://developer.visa.com/"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<section className="featured-img-xs">
														<Image
															alt="VISA Token Service Program"
															src="/img/ProjectIcons/Visa.png"
															className="project-icon-med-visa-xs"
														/>
													</section>
													<Row className="featured-item-xs">
														<Col className="featured-desc">
															<h3 className="featured-titles-xs">
																VISA Token
																Service Program:
																Latin America &
																Europe
															</h3>
															<p className="p-desc">
																Responsible for
																the
																implementation
																and integration
																of Visa Token
																Services to
																enable mobile
																payments using
																SecureElement
																(SE) and Host
																Card Emulation
																(HCE) on iOS and
																Android,
																respectively.
																Completed Mobile
																banking wallets
																for projects
																with
																BrasilPrePagos,
																Honda, VeriTran,
																HST, and others
																while working
																with
																technologies
																like{" "}
																<strong>
																	Java,
																	Android,
																	Soap Ui, ISO
																	8583, EMV Co
																	Tokenization
																	Standard,
																	RSA
																	Encryption,
																	& RSA-PKI.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://developer.visa.com/">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://www.nasa.gov/exploration/systems/orion/index.html"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<section className="featured-img-xs">
														<Image
															alt="NASA Software Engineer"
															src="/img/ProjectIcons/NASA.png"
															className="project-icon-med-nasa-xs"
														/>
													</section>
													<Row className="featured-item-xs">
														<Col className="featured-desc">
															<h3 className="featured-titles-xs">
																NASA Core Flight
																Software CCDD
																Web App
															</h3>
															<p className="p-desc">
																Web Application
																for users of the
																Orion Ascent
																Abort team who
																access command &
																Telemetry data
																from the Command
																& Data
																Dictionary
																Utility as
																provided by Core
																Flight Software
																& ITOS
																(Integrated Test
																& Operations
																System) using
																PostgreSQL to
																allow users to
																securely analyze
																robotics,
																spacecraft
																command &
																telemetry data
																in the CDD
																(without
																requiring them
																to have training
																on PostgreSQL
																itself).{" "}
																<strong>
																	Developed a
																	scalable
																	backend
																	using
																	Node.js, &
																	Express.js
																	utilizing an
																	MVC
																	Architecture
																	for
																	deployment
																	to
																	additional
																	servers for
																	use by the
																	Orion AA2
																	team members
																	by following
																	a REST
																	architecture
																	for optimal
																	software
																	reuse.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://www.nasa.gov/exploration/systems/orion/index.html">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="http://www.seabots.tech"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<section className="featured-img-xs">
														<Image
															alt="Subsea Robotics"
															src="/img/ProjectIcons/SeaBotsTech.png"
															className="project-icon-med-xs"
														/>
													</section>
													<Row className="featured-item-xs">
														<Col className="featured-desc">
															<h3 className="featured-titles">
																SeaBots
																Technology
															</h3>
															<p className="p-desc">
																Augmented
																Reality Gaming
																application on
																iOS to enable
																people to
																participate in
																the clean up of
																our Oceans by
																controlling a
																network of
																SubSea Robots to
																reclaim plastic
																particulate and
																mitigate oil
																spill disasters
																through their
																mobile devices.{" "}
																<strong>
																	Prototypes
																	created on
																	proto.io for
																	iOS and
																	implemented
																	in Xcode for
																	iOS on Swift
																	using ARkit
																	and Unreal
																	Engine in
																	C++.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={0}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="http://www.seabots.tech">
															Find out more
														</a>
													</Col>
												</Row>
											</Container>

											<Container className="github-cta">
												<a
													href="https://github.com/lopezdp"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="like-col-xs">
														<div className="like-btn-col-xs">
															<Image
																alt="Github"
																src="/img/LandingPageImages/LikeBtnIcon.png"
																className="like-btn-icon"
															/>
															<strong>
																More on Github
															</strong>
														</div>
													</Row>
												</a>
											</Container>
										</Container>
									</Jumbotron>

									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>Work Experience</h2>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Systems Architect &
															Sr. Engineer -{"  "}
														</Col>
													</Row>
													<Row className="resume-row">
														<section className="place">
															<a href="https://nativestack.io">
																NativeStack, Inc
															</a>
														</section>
														<section className="time-frame">
															{"  "}
															(01/2019 - Present)
														</section>
													</Row>
												</h3>
												<p className="experience-content experience-content-x">
													Leading the design of all
													software architecture,
													engineering, &
													implementation for multiple
													B2B facing applications.
													Architected & implemented a
													horizontally scalable,
													distributed cloud service
													platform using DynamoDB, AWS
													Lambda on the
													ServerlessFramework,
													React.js, & Node.js
													(including Python).
												</p>
											</section>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Systems Engineering
															Contractor -{"  "}
														</Col>
													</Row>
													<Row className="resume-row">
														<section className="place">
															<a href="https://paycargo.com">
																PayCargo, LLC
															</a>
														</section>
														<section className="time-frame">
															{"  "}
															(08/2018 – 12/2018)
														</section>
													</Row>
												</h3>

												<p className="experience-content experience-content-x">
													Contracted to implement
													InfoSec policies & controls,
													redeploy application to AWS
													cloud infrastructure,
													architect a blockchain
													solution & proof of concept,
													and to implement a SAML 2.0
													SSO authentication mechanism
													to enable secure system
													access for FedEx employees
													using the platform.
												</p>
											</section>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Technical Product
															Manager -{"  "}
														</Col>
													</Row>
													<Row className="resume-row">
														<section className="place">
															<a href="https://developer.visa.com">
																Visa, Inc
															</a>
														</section>
														<section className="time-frame">
															{"  "}
															(11/2017 – 10/2018)
														</section>
													</Row>
												</h3>

												<p className="experience-content experience-content-x">
													Subject Matter Expert (SME)
													for Visa Token Service Cloud
													Based Cryptographic Payment
													Platform. Responsible for
													the implementation and
													integration of Token
													Requestor-TSP and Issuer-TSP
													cloud-based payment
													platforms with issuing banks
													and technology solution
													providers across Latin
													America and Europe.
												</p>
											</section>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Software Engineering
															Contractor -{"  "}
														</Col>
													</Row>
													<Row className="resume-row">
														<section className="place">
															<a href="https://www.nasa.gov">
																NASA
															</a>
														</section>
														<section className="time-frame">
															{"  "}
															(06/2017 – 09/2017)
														</section>
													</Row>
												</h3>

												<p className="experience-content">
													Designed a Graphical User
													Interface (GUI) to interact
													with the CFS Command & Data
													Dictionary (CCDD), a
													comprehensive definition of
													all command & telemetry data
													used by NASA spacecraft and
													robotic systems to allows
													users to intuitively
													navigate mission critical
													information.
												</p>
											</section>
											<Container className="github-cta">
												<Row className="like-btn-col-resume-xs">
													<Col>
														<a
															href="https://davidplopez.com/assets/DPLResume.Lite.pdf"
															onClick={void 0}
															onTouchStart={() =>
																""
															}
															className="feat-link"
														>
															<Row className="like-col-xs">
																<div className="like-btn-col-xs one-pager">
																	<Image
																		alt="Github"
																		src="/img/LandingPageImages/LikeBtnIcon.png"
																		className="like-btn-icon"
																	/>
																	<strong>
																		Resume:
																		One Page
																	</strong>
																</div>
															</Row>
														</a>
														<a
															href="https://davidplopez.com/assets/DPLResume.V.pdf"
															onClick={void 0}
															onTouchStart={() =>
																""
															}
															className="feat-link"
														>
															<Row className="like-col-xs">
																<div className="like-btn-col-xs verbose-resume">
																	<Image
																		alt="Github"
																		src="/img/LandingPageImages/LikeBtnIcon.png"
																		className="like-btn-icon"
																	/>
																	<strong>
																		Resume:
																		Verbose
																	</strong>
																</div>
															</Row>
														</a>
													</Col>
												</Row>
											</Container>
										</Container>
									</Jumbotron>

									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container className="git-section">
											<h2>GitHub Contributions</h2>
											<p>
												This is a small history of my
												recent GitHub activity{" "}
												<em>excluding</em> any
												contributions made on any
												private Git instances hosted in
												an
												<em> On-Premise</em>{" "}
												environment. Please feel free to
												review my work to learn more
												about me and to generate any
												questions you want to ask when
												calling me for a consultation.
											</p>
											<div className="iframe-container2">
												<iframe
													src="https://github.com/sponsors/lopezdp/card"
													title="Sponsor lopezdp"
													allowFullScreen
													className="iframe-class1"
												></iframe>
											</div>

											<div className="iframe-container1">
												<iframe
													src="/github.html"
													allowFullScreen
													className="iframe-class1"
												></iframe>
											</div>

											<div className="gh-activity-feed">
												<GitHubFeed
													className="gh-window"
													fullName={"David P. Lopez"} // Provide Full Name as displayed on GitHub
													userName={"LopezDP"} // Provide User Name as displayed on Guthub
													avatarUrl={
														"https://avatars1.githubusercontent.com/u/5707191?s=460&v=4"
													} // Provide the avatar url of your github profile
													events={
														this.state.eventLists
													} // provide array of events using the users '/events' endpoint of github api
												/>
											</div>
										</Container>
									</Jumbotron>
								</Container>

								<Container className="aside-view-xs">
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<Link
												to="/software-revolt"
												className="feat-link"
											>
												<h2>Latest Blog Posts</h2>
												<p className="blog-intro">
													My general interests &
													thoughts on & about the
													technologies I work with,
													and how I solve the
													different problems that I
													iterate through everyday.
												</p>
											</Link>
											<Row className="find-more">
												<Col xs={0}></Col>
												<Col>
													<Image
														alt="External Link"
														src="/img/MiscIcons/ExternalLink.png"
														className="link-icon"
													/>

													<Link to="/software-revolt">
														Read more on the{" "}
														<em>Revolt Blog</em>
													</Link>
												</Col>
											</Row>

											<article
												id="blog-list"
												className="blog-list blog-list-x"
											>
												<h3>
													<Link
														className="title"
														to="/software-revolt/mac-os-dev-setup"
													>
														Setting up your
														Development Environment
														on MacOS like a Pro!
													</Link>
												</h3>
												<section>
													<summary>
														The object of this
														series is to help you
														get setup on any
														machine; Windows, Linux,
														or MacOS quickly. You
														will understand what is
														installed as a
														dependency on your
														machine and how to use
														each tool when you need
														it.
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<Link to="/software-revolt/mac-os-dev-setup">
																Read more
															</Link>
														</Col>
													</Row>
												</section>
											</article>

											<article
												id="blog-list"
												className="blog-list blog-list-x"
											>
												<h3>
													<a
														className="title"
														href="https://techcrunch.com/2020/01/29/all-eyes-are-on-the-next-liquidity-event-when-it-comes-to-space-startups/"
													>
														All eyes are on the next
														liquidity event when it
														comes to space startups
													</a>
												</h3>
												<section>
													<summary>
														At the FAA’s 23rd Annual
														Commercial Space
														Transportation
														Conference in
														Washington, DC on
														Wednesday,
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<a href="https://techcrunch.com/2020/01/29/all-eyes-are-on-the-next-liquidity-event-when-it-comes-to-space-startups/">
																Read more
															</a>
														</Col>
													</Row>
												</section>
											</article>
											<article
												id="blog-list"
												className="blog-list blog-list-x"
											>
												<h3>
													<a
														className="title"
														href="https://techcrunch.com/2020/01/29/ucsd-hospital-gets-a-drone-delivery-program-powered-by-matternet-and-ups/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29"
													>
														UCSD hospital gets a
														drone delivery program
														powered by Matternet and
														UPS
													</a>
												</h3>
												<section>
													<summary>
														Drone delivery may not
														make a lot of sense for
														food or parcel delivery
														yet, but for hospitals
														it could be a lifesaver.
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<a href="https://techcrunch.com/2020/01/29/ucsd-hospital-gets-a-drone-delivery-program-powered-by-matternet-and-ups/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29">
																Read more
															</a>
														</Col>
													</Row>
												</section>
											</article>
											<article
												id="blog-list"
												className="blog-list"
											>
												<h3>
													<a
														className="title"
														href="https://techcrunch.com/2020/01/29/verkada-security/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29"
													>
														Verkada raises $80M at
														$1.6B to be every
														building’s security OS
													</a>
												</h3>
												<section>
													<summary>
														Fifty iPads were stolen
														from Verkada co-founder
														Hans Robertson’s old
														company. Only when they
														checked the security
														system did they realize
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<a href="https://techcrunch.com/2020/01/29/verkada-security/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29">
																Read more
															</a>
														</Col>
													</Row>
												</section>
											</article>
										</Container>
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
				{/* NOTE: 1600px Desktop XL-Views!!!*/}
				<Media
					query="(min-width: 901px)"
					render={() => (
						<Container>
							<Container className="app-view">
								<Container className="main-view">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<h2>About Me</h2>
											<p>
												I help business owners with
												successful offline business
												enterprises, navigate the often
												confusing, and complex world of
												software development and
												technology. I am an experienced
												professional with many of my own
												startup successes and failures,
												and I have worked on many of the
												advanced technologies available,
												and soon to be available to the
												market. I want to help you and
												your teams leverage these tools
												successfully, because the time
												to build your online brand and
												business is now. I can guide you
												and help you automate your
												workflow and adapt to the
												changing market conditions that
												you need to take advantage of
												today to take your revenues on
												the path towards exponential
												growth.
											</p>
										</Container>
									</Jumbotron>
									<Testimonial />

									{/*
									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container className="btc-section">
											<h2>
												Bitcoin Payments Accepted Here
											</h2>
											<p>
												If you are currently doing
												business with me and you need to
												make a payment please use the
												bitcoin marketplace below.
											</p>

											<div className="iframe-container1">
												<iframe
													src="https://btcpay.ecomindustries.io/apps/5QW6V7iAAh7pyPZKxZfaXWcNMib/pos"
													allowFullScreen
													className="iframe-class1 btc-pay"
													style={{
														maxWidth: "100%",
														border: " 0",
													}}
												></iframe>
											</div>
										</Container>
									</Jumbotron>
									*/}
									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<h2>Current Projects</h2>
											<Container className="feature-content">
												<h3 className="feature-content-title">
													ECOM CRM
												</h3>
												<p className="feature-summary">
													Finally a low cost CRM and
													ERP solution that adds value
													to your bottom line to allow
													you to keep your business
													data and do with it as you
													see fit. Your Platform, Your
													Data.
												</p>
												<article className="feature-image has-ribbon">
													<Image
														alt="Feature Project"
														src="/img/LandingPageImages/FeaturedProject.png"
														className="project-image"
													/>
													<article className="ribbon">
														<section className="ribbon-text">
															NEW
														</section>
													</article>
												</article>
												<article className="feature-description">
													A new CRM and ERP solution
													to replace expensive
													platforms like NetSuite and
													SalesForce so you can focus
													on what you do best without
													liquidating your bottom
													line. A serverless
													application built with
													DynamoDB, AWS Lambda
													(ServerlessFramework),
													React.js, and Node.js
													(Python is used in data
													intensive services). Your
													data is always accessible
													because you own it. No need
													to fear the loss of your
													data because of an inability
													to pay your subscription
													fees.
												</article>
												<Container className="back-project-cta">
													<a
														href="https://saas.ecomindustries.io/auto-pilot"
														onClick={void 0}
														onTouchStart={() => ""}
														className="feat-link"
													>
														<Row className="like-col">
															<div className="like-btn-col">
																<Image
																	alt="Gmail"
																	src="/img/LandingPageImages/LikeBtnIcon.png"
																	className="like-btn-icon"
																/>
																<strong>
																	Back My
																	Project
																</strong>
															</div>
														</Row>
													</a>
												</Container>
											</Container>
											<hr className="divider" />
											<Container>
												{/*
												<a
													href="https://chargebackguardians.com"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="ChargebackGuardians"
																src="https://cdn-assets.tudorcapital.ventures/brand-logos/CBG.Icon.png"
																className="project-icon-qat"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																ChargebackGuardians:
																Loss Prevention
																Alerts &
																Recovery
															</h3>
															<p className="p-desc">
																An Artificially
																Intelligent
																Dispute
																Resolution
																Platform that
																automates the
																resolution of
																chargebacks with
																connections to
																Ethoca
																(MasterCard) &
																Verifi (Visa) to
																provide a
																comprehensive,
																multi-layer
																approach to
																resolving
																disputes and
																preventing
																chargebacks.
																Automating the
																resolution of
																chargebacks
																using
																DisputeGuardAi
																and FraudGuardAi
																Prevention
																Alerts to Refund
																transaction
																disputes, we
																help you prevent
																threshold
																breaches, and
																keep merchant
																accounts in good
																standing. The
																technology stack
																deployed uses{" "}
																<strong>
																	DynamoDB
																	Data
																	Streaming,
																	AWS Lambda
																	with the
																	ServerlessFramework,
																	React.js,
																	Node.js,
																	Python,
																	Infrastructure
																	As Code,
																	CodeBuild
																	for
																	Continuous
																	Integration,
																	CodePipeline
																	for
																	Continuous
																	Deployment,
																	CloudWatch
																	for
																	Application
																	Logs, Amazon
																	Cognito for
																	User
																	Authentication
																	&
																	Authorization,
																	API Gateway
																	for Endpoint
																	Security,
																	NativeStack
																	on the AWS
																	Cloud.
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://chargebackguardians.com">
															Find out more
														</a>
													</Col>
												</Row>
												*/}

												{/*
												<a
													href="https://dev.d3dbxu13fdlypu.amplifyapp.com/"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="Southland Electrical"
																src="https://cdn-assets.nativestack.io/NativeStackMerchantResources/SouthlandElectricAssets/SES_OFFICIAL_LOGO_2016.png"
																className="project-icon-qat"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																SouthlandElectrical:
																Industry Leaders
																in Motor Control
																Centers
															</h3>
															<p className="p-desc">
																Southland is an
																industry leader
																in custom built
																low and medium
																voltage motor
																control center
																buckets and
																verticals. Our
																extensive
																inventory of MCC
																buckets and
																verticals spans
																over 75 years
																from every major
																manufacturer
																including
																obsolete and
																discontinued
																designs, and our
																UL 508A Panel
																Shop is
																certified to
																build custom MCC
																buckets and
																panels. We
																specialize life
																extension/modernization
																and include ARC
																flash
																reduction/mitigation
																and smart MCC’s
																that incorporate
																communications
																and remote
																switching. Our
																quick ship
																program includes
																new, new surplus
																and
																professionally
																reconditioned
																MCC projects.
																The technology
																stack deployed
																uses{" "}
																<strong>
																	DynamoDB
																	Data
																	Streaming,
																	AWS Lambda
																	with the
																	ServerlessFramework,
																	React.js,
																	Node.js,
																	Python,
																	Infrastructure
																	As Code,
																	CodeBuild
																	for
																	Continuous
																	Integration,
																	CodePipeline
																	for
																	Continuous
																	Deployment,
																	CloudWatch
																	for
																	Application
																	Logs, Amazon
																	Cognito for
																	User
																	Authentication
																	&
																	Authorization,
																	API Gateway
																	for Endpoint
																	Security,
																	NativeStack
																	on the AWS
																	Cloud
																	ElasticSearch
																	Service
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://dev.d3dbxu13fdlypu.amplifyapp.com/">
															Find out more
														</a>
													</Col>
												</Row>
												*/}
												{/*

												<a
													href="https://tudorcapital.fund"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="TudorCapital Funding"
																src="https://cdn-assets.tudorcapital.ventures/brand-logos/TudorCapBrand.svg"
																className="project-icon-qat"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																TudorCapital:
																Small Business
																Funding &
																Merchant Account
																Payments
																Processing
															</h3>
															<p className="p-desc">
																Online funding
																platform for
																small business
																owners who need
																capital &
																funding to help
																scale their
																business online.
																Connect, Verify,
																& Fund with
																TudorCapital to
																build an
																ecommerce brand
																on an integrated
																lending platform
																that guarantees
																the health &
																sustainability
																of a business.
																The technology
																stack deployed
																uses{" "}
																<strong>
																	AWS Amplify,
																	DynamoDB,
																	AWS Lambda
																	on the
																	Serverless
																	Framework,
																	React.js,
																	Node.js
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://tudorcapital.fund">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://dx.bloktime.io"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="Blockchain Engineer"
																src="/img/ProjectIcons/BlokTime.png"
																className="project-icon-med"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																NFT Marketplace
															</h3>
															<p className="p-desc">
																Web Application
																for BlokTime NFT
																Marketplace,
																designed to
																facilitate user
																interaction with
																digital assets
																via ERC721 and
																ERC1155 smart
																contracts.
																Utilized
																Web3Modal for
																seamless wallet
																integration,
																enabling
																interactions
																with the
																Ethereum
																blockchain
																through a Chrome
																extension.{" "}
																<strong>
																	Developed
																	smart
																	contracts
																	using
																	HardHat,
																	ensuring
																	robust
																	compilation,
																	migration,
																	and
																	deployment.
																	Leveraged
																	Ethers.js/Web3js
																	libraries
																	for
																	efficient
																	interaction
																	with the
																	Ethereum
																	network and
																	smart
																	contracts.
																	Integrated
																	Open
																	Zeppelin
																	packages for
																	secure and
																	audited
																	implementation
																	of
																	blockchain
																	standards.
																	Employed
																	IPFS for
																	decentralized
																	and
																	peer-to-peer
																	sharing of
																	asset
																	metadata.
																	Implemented
																	unit testing
																	of smart
																	contracts
																	using Mocha
																	and Chai.
																	Frontend
																	developed
																	using
																	React.js,
																	HTML, and
																	CSS.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://dx.bloktime.io">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://wallet.bloktime.io"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="Blockchain Engineer"
																src="/img/ProjectIcons/BlokTime.png"
																className="project-icon-med"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																Web3 Wallet, NFT
																Music Player, &
																Live-Streaming
															</h3>
															<p className="p-desc">
																Web Application
																for a
																Decentralized
																Wallet,
																engineered to
																power a live
																streaming
																shopping
																platform and
																Web3 music
																experience on
																iOS Safari
																browsers.
																Enables users to
																effortlessly
																engage with
																merchants and
																execute
																one-click crypto
																payments, while
																also providing
																the capability
																to play Web3
																music as NFTs
																directly from
																the wallet
																interface.
																Integrated with
																the Ethereum
																blockchain, the
																wallet leverages
																Web3Modal for a
																seamless and
																secure user
																experience.
																Designed to
																facilitate
																real-time
																transactions
																using various
																cryptocurrencies,
																the wallet
																ensures
																instantaneous
																and secure
																payments to
																merchants during
																live streams.
																The wallet's
																multi-functional
																design also
																allows for the
																purchase,
																storage, and
																playback of Web3
																music NFTs,
																offering a
																comprehensive
																digital asset
																experience.{" "}
																<strong>
																	Developed
																	smart
																	contracts
																	using
																	HardHat,
																	ensuring
																	robust
																	compilation,
																	migration,
																	and
																	deployment.
																	Leveraged
																	Ethers.js/Web3js
																	libraries
																	for
																	efficient
																	interaction
																	with the
																	Ethereum
																	network and
																	smart
																	contracts.
																	Integrated
																	Open
																	Zeppelin
																	packages for
																	secure and
																	audited
																	implementation
																	of
																	blockchain
																	standards.
																	Employed
																	IPFS for
																	decentralized
																	and
																	peer-to-peer
																	sharing of
																	asset
																	metadata.
																	Implemented
																	unit testing
																	of smart
																	contracts
																	using Mocha
																	and Chai.
																	Frontend
																	developed
																	using
																	React.js,
																	HTML, and
																	CSS.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://wallet.bloktime.io">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://renew.quickautotags.com"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="California DMV"
																src="/img/ProjectIcons/Logo.QAT.png"
																className="project-icon-qat"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																Quick Auto Tags:
																MVP California
																DMV Renewal
																Registration
																System
															</h3>
															<p className="p-desc">
																Online Auto Tag
																registration
																renewal platform
																that connects to
																a government API
																with encryption
																requirements for
																data{" "}
																<em>at-rest</em>{" "}
																and{" "}
																<em>
																	in-transit
																</em>{" "}
																to secure the
																automation of
																auto tag
																renewals,
																vehicle title
																transfers, and
																other services
																offered by the
																California DMV.
																The technology
																stack deployed
																uses{" "}
																<strong>
																	DynamoDB,
																	AWS Lambda
																	on the
																	Serverless
																	Framework,
																	React.js,
																	Node.js
																</strong>
																.
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://renew.quickautotags.com">
															Find out more
														</a>
													</Col>
												</Row>
*/}
												{/*
												<a
													href="https://SpecTruMetRx.com"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="Medical Payments"
																src="https://spectrumetrx.com/img/dicom.png"
																className="project-icon-med-spec"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																SpecTruMetRx:
																MVP Medical
																Payments &
																Analytics on the
																Blockchain
															</h3>
															<p className="p-desc">
																Healthcare
																Analytics &
																Digital Medical
																Payments and
																Lending Platform
																on the
																Blockchain. Ai
																based payments
																network for
																healthcare
																providers built
																on{" "}
																<strong>
																	DynamoDB,
																	AWS Lambda
																	on the
																	Serverless
																	Framework,
																	React.js, &
																	Node.js
																	built with
																	Infrastructure
																	As Code
																	Architecture
																	on
																	CloudFormation
																	using Amazon
																	Cognito,
																	CodeBuild,
																	CodePipeline,
																	APIGateway,
																	& Native to
																	AWS.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://SpecTruMetRx.com">
															Find out more
														</a>
													</Col>
												</Row>
												*/}
											</Container>
										</Container>
									</Jumbotron>

									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<h2>Previous Projects</h2>
											<Container>
												<Link
													to="/ai-machine-learning-research"
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="Machine Learning & Artificial Intelligence"
																src="/img/ProjectIcons/AI.png"
																className="project-icon-qat"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																Machine Learning
																Research
															</h3>
															<p className="p-desc">
																Artificial
																Intelligence &
																Machine Learning
																OpenSource
																research
																repository.
																Created for the
																MDC STEM
																Research
																Institute, these
																project
																represent a
																small selection
																of work I
																created to
																analyze data and
																predict the
																outcomes
																questioned
																within the
																research
																published in
																these archives.
																Machine Learning
																Research -
																Preprocessing,
																Feature
																Engineering,
																Modeling,
																Learning/Training,
																and Predictions
																in ML:{" "}
																<strong>
																	JupyterNotebook,
																	Python,
																	TensorFlow,
																	Pandas,
																	NumPy,
																	SciKit-Learn,
																	MatPlotLib,
																	Theano,
																	Keras,
																	Seaborn, &
																	NLTK.
																</strong>
															</p>
														</Col>
													</Row>
												</Link>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<Link to="/ai-machine-learning-research">
															Find out more
														</Link>
													</Col>
												</Row>
												<a
													href="https://developer.visa.com/"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="VISA Token Service Program"
																src="/img/ProjectIcons/Visa.png"
																className="project-icon-med-visa"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																VISA Token
																Service Program:
																Latin America &
																Europe
															</h3>
															<p className="p-desc">
																Responsible for
																the
																implementation
																and integration
																of Visa Token
																Services to
																enable mobile
																payments using
																SecureElement
																(SE) and Host
																Card Emulation
																(HCE) on iOS and
																Android,
																respectively.
																Completed Mobile
																banking wallets
																for projects
																with
																BrasilPrePagos,
																Honda, VeriTran,
																HST, and others
																while working
																with
																technologies
																like{" "}
																<strong>
																	Java,
																	Android,
																	SoapUi,
																	ISO8583,
																	EMVCo
																	Tokenization
																	Standard,
																	RSA
																	Encryption,
																	& RSA-PKI.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://developer.visa.com/">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="https://www.nasa.gov/exploration/systems/orion/index.html"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="NASA Software Engineer"
																src="/img/ProjectIcons/NASA.png"
																className="project-icon-med-nasa"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																NASA CFS CCDD
																Web Application
															</h3>
															<p className="p-desc">
																Web Application
																for users of the
																Orion Ascent
																Abort team who
																access command &
																Telemetry data
																from the Command
																& Data
																Dictionary
																Utility as
																provided by Core
																Flight Software
																& ITOS
																(Integrated Test
																& Operations
																System) using
																PostgreSQL to
																allow users to
																securely analyze
																robotics,
																spacecraft
																command &
																telemetry data
																in the CDD
																(without
																requiring them
																to have training
																on PostgreSQL
																itself).{" "}
																<strong>
																	Developed a
																	scalable
																	backend
																	using
																	Node.js, &
																	Express.js
																	utilizing an
																	MVC
																	Architecture
																	for
																	deployment
																	to
																	additional
																	servers for
																	use by the
																	Orion AA2
																	team members
																	by following
																	a REST
																	architecture
																	for optimal
																	software
																	reuse.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://www.nasa.gov/exploration/systems/orion/index.html">
															Find out more
														</a>
													</Col>
												</Row>

												<a
													href="http://www.seabots.tech"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="featured-item">
														<Col
															xs={2}
															className="featured-img"
														>
															<Image
																alt="Subsea Robotics"
																src="/img/ProjectIcons/SeaBotsTech.png"
																className="project-icon-med"
															/>
														</Col>
														<Col className="featured-desc">
															<h3 className="featured-titles">
																SeaBots
																Technology
															</h3>
															<p className="p-desc">
																Augmented
																Reality Gaming
																application on
																iOS to enable
																people to
																participate in
																the clean up of
																our Oceans by
																controlling a
																network of
																SubSea Robots to
																reclaim plastic
																particulate and
																mitigate oil
																spill disasters
																through their
																mobile devices.{" "}
																<strong>
																	Prototypes
																	created on
																	proto.io for
																	iOS and
																	implemented
																	in Xcode for
																	iOS on Swift
																	using ARkit
																	and Unreal
																	Engine in
																	C++.
																</strong>
															</p>
														</Col>
													</Row>
												</a>
												<Row className="find-more">
													<Col xs={2}></Col>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="http://www.seabots.tech">
															Find out more
														</a>
													</Col>
												</Row>
											</Container>
											<Container className="github-cta">
												<a
													href="https://github.com/lopezdp"
													onClick={void 0}
													onTouchStart={() => ""}
													className="feat-link"
												>
													<Row className="like-col">
														<div className="like-btn-col">
															<Image
																alt="Github"
																src="/img/LandingPageImages/LikeBtnIcon.png"
																className="like-btn-icon"
															/>
															<strong>
																More on Github
															</strong>
														</div>
													</Row>
												</a>
											</Container>
										</Container>
									</Jumbotron>

									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<h2>Work Experience</h2>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Systems Architect &
															Sr. Engineer -{"  "}
															<section className="place">
																<a href="https://nativestack.io">
																	NativeStack,
																	Inc
																</a>
															</section>
															<section className="time-frame">
																{"  "}
																(01/2019 -
																Present)
															</section>
														</Col>
													</Row>
												</h3>
												<p className="experience-content experience-content-x">
													Leading the design of all
													software architecture,
													engineering, &
													implementation for multiple
													B2B facing applications.
													Architected & implemented a
													horizontally scalable,
													distributed cloud service
													platform using DynamoDB, AWS
													Lambda on the
													ServerlessFramework,
													React.js, & Node.js
													(including Python).
												</p>
											</section>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Systems Engineering
															Contractor -{"  "}
															<section className="place">
																<a href="https://paycargo.com">
																	PayCargo,
																	LLC
																</a>
															</section>
															<section className="time-frame">
																{"  "}
																(08/2018 –
																12/2018)
															</section>
														</Col>
													</Row>
												</h3>
												<p className="experience-content experience-content-x">
													Contracted to implement
													InfoSec policies & controls,
													redeploy application to AWS
													cloud infrastructure,
													architect a blockchain
													solution & proof of concept,
													and to implement a SAML 2.0
													SSO authentication mechanism
													to enable secure system
													access for FedEx employees
													using the platform.
												</p>
											</section>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Technical Product
															Manager -{"  "}
															<section className="place">
																<a href="https://developer.visa.com">
																	Visa, Inc
																</a>
															</section>
															<section className="time-frame">
																{"  "}
																(11/2017 –
																10/2018)
															</section>
														</Col>
													</Row>
												</h3>
												<p className="experience-content experience-content-x">
													Subject Matter Expert (SME)
													for Visa Token Service Cloud
													Based Cryptographic Payment
													Platform. Responsible for
													the implementation and
													integration of Token
													Requestor-TSP and Issuer-TSP
													cloud-based payment
													platforms with issuing banks
													and technology solution
													providers across Latin
													America and Europe.
												</p>
											</section>
											<section className="experience">
												<h3 className="job-title">
													<Row className="resume-row">
														<Col className="resume-item">
															Software Engineering
															Contractor -{"  "}
															<section className="place">
																<a href="https://www.nasa.gov">
																	NASA
																</a>
															</section>
															<section className="time-frame">
																{"  "}
																(06/2017 –
																09/2017)
															</section>
														</Col>
													</Row>
												</h3>
												<p className="experience-content">
													Designed a Graphical User
													Interface (GUI) to interact
													with the CFS Command & Data
													Dictionary (CCDD), a
													comprehensive definition of
													all command & telemetry data
													used by NASA spacecraft and
													robotic systems to allows
													users to intuitively
													navigate mission critical
													information.
												</p>
											</section>
											<Container className="github-cta">
												<Row className="like-btn-col-resume">
													<Col>
														<a
															href="https://davidplopez.com/assets/DPLResume.Lite.pdf"
															onClick={void 0}
															onTouchStart={() =>
																""
															}
															className="feat-link"
														>
															<Row className="like-col">
																<div className="like-btn-col-xs one-pager">
																	<Image
																		alt="Github"
																		src="/img/LandingPageImages/LikeBtnIcon.png"
																		className="like-btn-icon-resume"
																	/>
																	<strong>
																		Resume:
																		One Page
																	</strong>
																</div>
															</Row>
														</a>
													</Col>
													<Col>
														<a
															href="https://davidplopez.com/assets/DPLResume.V.pdf"
															onClick={void 0}
															onTouchStart={() =>
																""
															}
															className="feat-link"
														>
															<Row className="like-col">
																<div className="like-btn-col-xs verbose-resume">
																	<Image
																		alt="Github"
																		src="/img/LandingPageImages/LikeBtnIcon.png"
																		className="like-btn-icon-resume"
																	/>
																	<strong>
																		Resume:
																		Verbose
																	</strong>
																</div>
															</Row>
														</a>
													</Col>
												</Row>
											</Container>
										</Container>
									</Jumbotron>

									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container className="git-section">
											<h2>GitHub Contributions</h2>
											<p>
												This is a small history of my
												recent GitHub activity{" "}
												<em>excluding</em> any
												contributions made on any
												private Git instances hosted in
												an
												<em> On-Premise</em>{" "}
												environment. Please feel free to
												review my work to learn more
												about me and to generate any
												questions you want to ask when
												calling me for a consultation.
											</p>
											<div className="iframe-container2">
												<iframe
													src="https://github.com/sponsors/lopezdp/card"
													title="Sponsor lopezdp"
													allowFullScreen
													className="iframe-class1"
												></iframe>
											</div>
											<div className="iframe-container1">
												<iframe
													src="/github.html"
													allowFullScreen
													className="iframe-class1"
												></iframe>
											</div>

											<div className="gh-activity-feed">
												<GitHubFeed
													className="gh-window"
													fullName={"David P. Lopez"} // Provide Full Name as displayed on GitHub
													userName={"LopezDP"} // Provide User Name as displayed on Guthub
													avatarUrl={
														"https://avatars1.githubusercontent.com/u/5707191?s=460&v=4"
													} // Provide the avatar url of your github profile
													events={
														this.state.eventLists
													} // provide array of events using the users '/events' endpoint of github api
												/>
											</div>
										</Container>
									</Jumbotron>
								</Container>

								<Container className="aside-view">
									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<Row>
												<Col xs={1}>
													<Image
														alt="Location"
														src="/img/ContactIcons/Location.png"
														className="contact-icon"
													/>
												</Col>
												<Col className="contact-info">
													<p>Miami, Florida, USA</p>
												</Col>
											</Row>
											<Row>
												<Col xs={1}>
													<Image
														alt="Email"
														src="/img/ContactIcons/Email.png"
														className="contact-icon"
													/>
												</Col>
												<Col className="contact-info">
													<p>
														<a href="mailto:David P. Lopez<David@NativeStack.io>?subject=I%20need%20an%20engineer%20now!!!">
															Email Me Today!
														</a>
													</p>
												</Col>
											</Row>
											<Row>
												<Col xs={1}>
													<Image
														alt="Phone"
														src="/img/ContactIcons/Phone.png"
														className="contact-icon"
													/>
												</Col>
												<Col className="contact-info">
													<PhoneNumber
														number="+13054192441"
														isLinked={true}
													/>
												</Col>
											</Row>
										</Container>
									</Jumbotron>
									<Jumbotron
										className="section-heading-xs"
										fluid
									>
										<Container>
											<h2>10x Skill Set</h2>
											<p>
												I am a Sr. Automation Architect
												and Sr. Software Engineer with
												enterprise experience deploying
												apps, services, and Machine
												Learning tools on Amazon Web
												Services (AWS).
											</p>
											<div>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															First Principles
															Approach
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Thinking through First Principles"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>

												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																<i>
																	The
																	naturally
																	proper
																	direction of
																	our road is
																	from things
																	better known
																	and clearer
																	to us, to
																	things that
																	are clearer
																	and better
																	known by
																	nature.
																</i>
																<cite>
																	{" "}
																	- Aristotle
																	(Physica.
																	184a10–21)
																</cite>
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={100}
															/>
														</Col>
													</OverlayTrigger>
												</Row>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>ECOM Payments</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Digital Payments & Tokenization"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>

												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Contributor on
																digital payments
																projects working
																to develop
																digital wallets
																on Android for
																banking
																institutions in
																Latin America &
																Europe, to
																include projects
																with
																BrasilPrePagos,
																VeriTran, Honda,
																Swatch, and
																GooglePay as a
																member of the
																Visa Token
																Service
																Integration
																Team.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={100}
															/>
														</Col>
													</OverlayTrigger>
												</Row>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															JS, React.js,
															Node.js
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="The DARN Tech Stack"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>

												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I specialize in
																developing PWA's
																on AWS using
																DynamoDB, AWS
																Lambda,
																React.js, &
																Node.js{" "}
																<strong>
																	(DARN Stack)
																</strong>
																. I am an expert
																at API
																development with
																Express.js also.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={97}
															/>
														</Col>
													</OverlayTrigger>
												</Row>
												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Python, Flask,
															Jupyter
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Python & Flask & SageMaker Notebooks"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I excel at
																building data
																driven tools in
																Python to scrape
																web data with
																Selenium or
																Cheerio. I am a{" "}
																<em>
																	Data Science
																</em>{" "}
																expert and I can
																work with
																analysis modules
																like Pandas,
																NumPy,
																SciKit-Learn,
																TensorFlow,
																MatPlotLib,
																SciPy, and more.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={95}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>Java/C++</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Jave & C++ Object Oriented Programming"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Pro
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Object Oriented
																Programming
																expert in Java,
																C++, and C#. I
																can work
																effectively in
																both{" "}
																<em>Unity</em>{" "}
																and{" "}
																<em>
																	UnReal
																	Engine
																</em>{" "}
																to deploy
																Virtual &
																Augmented
																Reality
																applications for
																use cases in the
																B2B environment.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={85}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Blockchain & RSA-PKI
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Digital Encryption Expert"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Pro
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I am a
																professional at
																building{" "}
																<em>
																	mining rigs
																</em>{" "}
																to verify
																transactions on
																any
																cryptocurrency
																network. I can
																help you
																implement
																immutable
																transaction
																ledgers to
																deploy your own
																cryptocurrency
																or SmartContract
																with Ethereum or
																HyperLedger on
																AWS. I also
																provide the
																option of
																working with
																Amazon's new
																Quantum Ledger
																Database
																technology.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={85}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>HTML, CSS, JSON</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Responsive Design Master"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Everything I
																build is{" "}
																<em>
																	Responsive
																</em>{" "}
																and targeted to
																all mobile
																devices using
																modern web
																development
																techniques for
																presenting,
																laying out, and
																parsing or
																passing data on
																the web securely
																and dynamically.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={99}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Mobile App Developer
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Mobile Application Developer"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																I can deploy
																applications
																using
																technologies
																native to both
																Android and iOS
																devices or with{" "}
																<em>
																	crossplatform
																</em>{" "}
																architectures to
																deliver{" "}
																<strong>
																	<em>
																		Progressive
																		Web
																		Applications
																		(PWA's)
																	</em>
																</strong>{" "}
																that target any
																mobile device
																available on the
																market. Ignoring
																mobile is to
																ignore market
																share.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={95}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															API Developer & ReST
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="API Developer & ReST Expert"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Expert
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																Expert at the
																implementation
																of secure{" "}
																<em>
																	Application
																	Programming
																	Interfaces
																</em>{" "}
																that you can use
																to help your
																users leverage
																your platform to
																automate and
																scale to a
																global audience
																autonomously. An
																expert with RSA
																Encryption
																techniques that
																will enable you
																to share
																assymetric
																(public) keys
																with your users
																to let them
																access your
																platform with
																their own custom
																automation
																tools.
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={95}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row className="skills-xs">
													<section className="skills-title-xs">
														<h3>
															Ai & Machine
															Learning
														</h3>
													</section>
													<section className="skill-level-xs">
														<Image
															alt="Ai & Machine Learning Jedi"
															src="/img/MiscIcons/Info.png"
															className="info-icon"
														/>
														Pro
													</section>
												</Row>
												<Row>
													<OverlayTrigger
														key="bottom"
														placement="bottom"
														overlay={
															<Tooltip id="tooltip-bottom">
																The machines are
																coming. Join
																them with me. I
																am now on record
																for being a
																friend to the{" "}
																<strong>
																	Ai
																</strong>{" "}
																from day one.{" "}
																<em>
																	Are we in a
																	simulation
																</em>
																? Let me build
																you a{" "}
																<strong>
																	ChatBot
																</strong>{" "}
																that will talk
																to the machine
																for us and{" "}
																<i>
																	Let's find
																	out
																</i>
																! Adaptive &
																Assistive
																technologies are
																here, now is the
																time to lead the
																pack. Will you
																join me?
															</Tooltip>
														}
													>
														<Col className="skill-progress">
															<ProgressBar
																variant="info"
																now={90}
															/>
														</Col>
													</OverlayTrigger>
												</Row>

												<Row>
													<Col>
														<Image
															alt="External Link"
															src="/img/MiscIcons/ExternalLink.png"
															className="link-icon"
														/>

														<a href="https://profile.codersrank.io/user/lopezdp">
															See more on
															CodersRank
														</a>
													</Col>
												</Row>
											</div>
										</Container>
									</Jumbotron>
									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<h2>Languages</h2>
											<p className="language-context">
												<strong>English:</strong> Native
												Speaker
											</p>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="5"
												readonly
											/>
											<p className="language-context">
												<strong>Spanish:</strong>{" "}
												Native/Bilingual
											</p>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="5"
												readonly
											/>
											<p className="language-context">
												<strong>Portuguese:</strong>{" "}
												Fluent
											</p>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="4"
												readonly
											/>
											<OverlayTrigger
												key="bottom"
												placement="bottom"
												overlay={
													<Tooltip id="tooltip-bottom">
														обидно досадно но ладно
													</Tooltip>
												}
											>
												<p className="language-context">
													<strong>Russian:</strong>{" "}
													Read Only Proficiency
												</p>
											</OverlayTrigger>
											<Rating
												emptySymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/EmptyStar.png"
														className="review-star"
													/>
												}
												fullSymbol={
													<img
														alt="Top Rated Freelancer"
														src="/img/MiscIcons/FullStar.png"
														className="review-star"
													/>
												}
												initialRating="1"
												readonly
											/>
										</Container>
									</Jumbotron>
									<Jumbotron
										className="section-heading"
										fluid
									>
										<Container>
											<Link
												to="/software-revolt"
												className="feat-link"
											>
												<h2>Latest Blog Posts</h2>
												<p className="blog-intro">
													My general interests &
													thoughts on & about the
													technologies I work with,
													and how I solve the
													different problems that I
													iterate through everyday.
												</p>
											</Link>
											<Row className="find-more">
												<Col xs={0}></Col>
												<Col>
													<Image
														alt="External Link"
														src="/img/MiscIcons/ExternalLink.png"
														className="link-icon"
													/>

													<Link to="/software-revolt">
														Read more on the{" "}
														<em>Revolt Blog</em>
													</Link>
												</Col>
											</Row>

											<article
												id="blog-list"
												className="blog-list blog-list-x"
											>
												<h3>
													<Link
														className="title"
														to="/software-revolt/mac-os-dev-setup"
													>
														Setting up your
														Development Environment
														on MacOS like a Pro!
													</Link>
												</h3>
												<section>
													<summary>
														The object of this
														series is to help you
														get setup on any
														machine; Windows, Linux,
														or MacOS quickly. You
														will understand what is
														installed as a
														dependency on your
														machine and how to use
														each tool when you need
														it.
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<Link to="/software-revolt/mac-os-dev-setup">
																Read more
															</Link>
														</Col>
													</Row>
												</section>
											</article>

											<article
												id="blog-list"
												className="blog-list blog-list-x"
											>
												<h3>
													<a
														className="title"
														href="https://techcrunch.com/2020/01/29/all-eyes-are-on-the-next-liquidity-event-when-it-comes-to-space-startups/"
													>
														All eyes are on the next
														liquidity event when it
														comes to space startups
													</a>
												</h3>
												<section>
													<summary>
														At the FAA’s 23rd Annual
														Commercial Space
														Transportation
														Conference in
														Washington, DC on
														Wednesday,
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<a href="https://techcrunch.com/2020/01/29/all-eyes-are-on-the-next-liquidity-event-when-it-comes-to-space-startups/">
																Read more
															</a>
														</Col>
													</Row>
												</section>
											</article>
											<article
												id="blog-list"
												className="blog-list blog-list-x"
											>
												<h3>
													<a
														className="title"
														href="https://techcrunch.com/2020/01/29/ucsd-hospital-gets-a-drone-delivery-program-powered-by-matternet-and-ups/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29"
													>
														UCSD hospital gets a
														drone delivery program
														powered by Matternet and
														UPS
													</a>
												</h3>
												<section>
													<summary>
														Drone delivery may not
														make a lot of sense for
														food or parcel delivery
														yet, but for hospitals
														it could be a lifesaver.
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<a href="https://techcrunch.com/2020/01/29/ucsd-hospital-gets-a-drone-delivery-program-powered-by-matternet-and-ups/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29">
																Read more
															</a>
														</Col>
													</Row>
												</section>
											</article>
											<article
												id="blog-list"
												className="blog-list"
											>
												<h3>
													<a
														className="title"
														href="https://techcrunch.com/2020/01/29/verkada-security/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29"
													>
														Verkada raises $80M at
														$1.6B to be every
														building’s security OS
													</a>
												</h3>
												<section>
													<summary>
														Fifty iPads were stolen
														from Verkada co-founder
														Hans Robertson’s old
														company. Only when they
														checked the security
														system did they realize
													</summary>
													<Row>
														<Col>
															<Image
																alt="External Link"
																src="/img/MiscIcons/ExternalLink.png"
																className="link-icon"
															/>

															<a href="https://techcrunch.com/2020/01/29/verkada-security/?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+techcrunch%2Fstartups+%28TechCrunch+»+Startups%29">
																Read more
															</a>
														</Col>
													</Row>
												</section>
											</article>
										</Container>
									</Jumbotron>
								</Container>
							</Container>

							{/* Need the Footer Menu to display new features */}
							<Footer />
						</Container>
					)}
				/>
			</div>
		);
	}
}
